import React from "react"
import { Link, graphql } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoHistoryBlock from "../components/VideoHistoryBlock"
import Breadcrumbs from "../components/breadcrumb"

const TagTemplate = ({ data, pageContext }) => (
  <Layout>
    <SEO title={`Tag: ${pageContext.tag}`} />
    <Row>
      <Col id="page-title" className="col col-12">
        <h1 className="title">{`Tag: ${pageContext.tag}`}</h1>
      </Col>
    </Row>
    <Breadcrumbs />
    <Row id="content">
      <Col className="results-summary py-3">
        <strong>{data.allMarkdownRemark.totalCount} Results</strong>
      </Col>
    </Row>
    <Row>
      <Col>
        <ul>
          {data.allMarkdownRemark.nodes.map((item, key) => (
            <li key={key}>
              <Link to={item.frontmatter.slug}>{item.frontmatter.title}</Link>
            </li>
          ))}
        </ul>
      </Col>
      <Col md="12" lg="3">
        <div id="sidebar-second" className="sidebar">
          <VideoHistoryBlock />
        </div>
      </Col>
    </Row>
    <Row className="text-center">
      <Col>
        <Link to="/" className="highlight-link-yellow">
          Go back to the homepage
        </Link>
      </Col>
    </Row>
  </Layout>
)

export const query = graphql`
  query TagQuery($tag: [String]) {
    allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: { frontmatter: { tags: { in: $tag }, slug: { ne: null } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
  }
`

export default TagTemplate
